export const englishMessages = {
  achievement1Heading: '12,500+ Installs',
  achievement2Heading: '500,000+ Questions Answered',
  achievement3Heading: '25,000+ Lessons Completed',
  mainBlack1: 'Our kids grow fast,',
  mainAlmond: 'our numbers',
  mainBlack2: 'too',
  heading: 'OUR ACHIEVEMENT',
  achievement1Text: 'Parents all around Indonesia have downloaded Titik Pintar on their phones',
  achievement2Text: 'Our children are eager to learn and answer the questions every day!',
  achievement3Text: 'Our teachers are working hard to create the most engaging lessons for your kids!',
};

export const bahasaMessages = {
  achievement1Heading: '12,500+ Install',
  achievement2Heading: '500,000+ Pertanyaan Terjawab',
  achievement3Heading: '25,000+ Pelajaran Diselesaikan',
  mainBlack1: 'Anak-anak Titik Pintar tumbuh cepat,',
  mainAlmond: 'begitu pun dengan kami',
  mainBlack2: ' ',
  heading: 'PENCAPAIAN KAMI',
  achievement1Text: 'Banyak orang tua dari berbagai daerah di Indonesia sudah mengunduh Titik Pintar di handphone-nya.',
  achievement2Text: 'Anak-anak di Titik Pintar semangat menjawab soal-soal setiap hari!',
  achievement3Text: 'Guru-guru kami bekerja keras untuk membuat soal yang interaktif untuk anak-anak!',
};
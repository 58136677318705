import React, { useEffect, useState } from 'react';
import { IntlProvider, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import RightArrow from '../../Assets/Images/right-arrow.png';
import DGGFLogo from '../../Assets/Images/dggf-logo.png';
import TimelineLogo2 from '../../Assets/Images/timeline-july-19.png';
import TimelineLogo3 from '../../Assets/Images/timeline-oct-19.png';
import TimelineLogo5 from '../../Assets/Images/timeline-nov-19.png';
import TimelineLogo6 from '../../Assets/Images/timeline-mar-20.png';
import TimelineLogo7 from '../../Assets/Images/timeline-apr-20.png';
import TimelineLogo8 from '../../Assets/Images/timeline-sep-20.png';
import TimelineLogo9 from '../../Assets/Images/timeline-oct-20.png';
import TimelineLogo10 from '../../Assets/Images/timeline-nov-20.png';
import { englishMessages, bahasaMessages } from './JourneyTimelineMessages';
import './JourneyTimeline.scss';

const JourneyTimeline = ({ isIndoSelected }) => {

  const [nextClicks, setNextClicks] = useState(0);

  const totalTimesShown = 10;

  const [isNextActive, setIsNextActive] = useState(true);

  const [isPrevActive, setIsPrevActive] = useState(false);

  const [activeTime, setActiveTime] = useState(0);

  useEffect(() => {
    if (window.innerWidth > 900) {
      document.getElementById('journey-timeline').style.transform = `translateX(calc(-17.1% * ${nextClicks}))`
    }
    else if (window.innerWidth < 900 && window.innerWidth >= 500) {
      document.getElementById('journey-timeline').style.transform = `translateX(calc(-80% * ${nextClicks}))`
    }
    else if (window.innerWidth < 500) document.getElementById('journey-timeline').style.transform = `translateX(calc(-100% * ${nextClicks}))`
    toggleNextActive();
    togglePrevActive();
  }, [nextClicks]);

  useEffect(() => {
    if (window.innerWidth > 900) {
      if (activeTime -  nextClicks === 4) {
        if (nextClicks === 0) return setNextClicks(1);
        return document.getElementById('journey-timeline').style.transform = `translateX(calc(-34% * ${nextClicks}))`;
      }
    }

    else if (window.innerWidth <= 900 && window.innerWidth > 700) {
      if (activeTime - nextClicks === 1) {
        document.getElementById('journey-timeline').style.transform = `translateX(calc(-80% * ${nextClicks + 1}))`;
        setNextClicks((num) => num + 1);
      }
    }

    else {
      if (activeTime - nextClicks === 1) {
        document.getElementById('journey-timeline').style.transform = `translateX(calc(-100% * ${nextClicks + 1}))`;
        setNextClicks((num) => num + 1);
      }
    }
  }, [activeTime]);

  const toggleNextActive = () => {
    if (window.innerWidth > 900) {
      if (totalTimesShown - 5 - nextClicks === 0) return setIsNextActive(false);
      else return setIsNextActive(true);
    }
    else {
      if (totalTimesShown - 1 - nextClicks === 0) return setIsNextActive(false);
      else return setIsNextActive(true);
    }
  };

  const togglePrevActive = () => {
    if (window.innerWidth > 900) {
      if (nextClicks > 0) return setIsPrevActive(true);
      else return setIsPrevActive(false);
    }
    else {
      if (totalTimesShown - nextClicks < totalTimesShown) return setIsPrevActive(true);
      else return setIsPrevActive(false);
    }
  };

  return (
    <IntlProvider
      messages={isIndoSelected ? bahasaMessages : bahasaMessages}
      locale='en'
      defaultLocale='id'
    >

      <div className='journey-timeline-container' >

        <button 
          className={`journey-timeline-btn left ${isPrevActive ? 'active' : ''}`}
          onClick={() => isPrevActive ? setNextClicks((num) => num - 1) : null}
        >
          <img src={RightArrow} alt='Tanda panah kanan' />
        </button>

        <div className='journey-timeline' id='journey-timeline' >

          <div className='journey-timeline-time first' /> 

          <div className='journey-timeline-time' >
            {activeTime === 0 ? (
              <React.Fragment>
                <div className='journey-timeline-time-dot-active' />
                <div className='journey-timeline-time-dot-active-inner' />
                <span className='journey-timeline-time-date active' >
                  <FormattedMessage id='time1Month' />
                </span>
                
                <div className='journey-timeline-time-info' >

                  <div className='journey-timeline-time-info-triangle' />

                  <div className='journey-timeline-time-info-left' >
                    <img src={DGGFLogo} alt='Logo dutch good growth fund' />
                  </div>
                  <div className='journey-timeline-time-info-right' >
                    <FormattedMessage id='time1Text' />
                    {/* Received a grant from the 
                    <span className='almond-text' > Dutch Good Growth Fund (DGGF) </span>
                    from the Kingdom of Netherlands. */}
                  </div>
                </div>

              </React.Fragment>
            ) : (
              <React.Fragment>
                <div 
                  className='journey-timeline-time-dot'
                  onClick={() => setActiveTime(0)}
                />
                <span 
                  className='journey-timeline-time-date'
                  onClick={() => setActiveTime(0)}
                >
                  <FormattedMessage id='time1Month' />
                </span>
              </React.Fragment>
            )}
          </div>

          <div className='journey-timeline-time' >
          {activeTime === 1 ? (
            <React.Fragment>
              <div className='journey-timeline-time-dot-active' />
              <div className='journey-timeline-time-dot-active-inner' />
              <span className='journey-timeline-time-date active' >
                <FormattedMessage id='time2Month' />
              </span>

              <div className='journey-timeline-time-info top' >
                <div className='journey-timeline-time-info-left' >
                  <img src={TimelineLogo2} alt='Logo timeline july 2019' />
                </div>
                <div className='journey-timeline-time-info-right' >
                  <FormattedMessage id='time2Text' />
                </div>

                <div className='journey-timeline-time-info-triangle-bottom' />
              </div>

            </React.Fragment>
            ) : (
              <React.Fragment>
                <div
                  className='journey-timeline-time-dot'
                  onClick={() => setActiveTime(1)}
                />
                <span
                  className='journey-timeline-time-date'
                  onClick={() => setActiveTime(1)}
                >
                  <FormattedMessage id='time2Month' />
                </span>
              </React.Fragment>
            )}
          </div>

          <div className='journey-timeline-time' >
          {activeTime === 2 ? (
            <React.Fragment>
              <div className='journey-timeline-time-dot-active' />
              <div className='journey-timeline-time-dot-active-inner' />
              <span className='journey-timeline-time-date active' >
                <FormattedMessage id='time3Month' />
              </span>

              <div className='journey-timeline-time-info' >
                <div className='journey-timeline-time-info-left' >
                  <img src={TimelineLogo3} alt='Logo timeline oktober 2019' />
                </div>
                <div className='journey-timeline-time-info-right' >
                  <FormattedMessage id='time3Text' />
                </div>

                <div className='journey-timeline-time-info-triangle' />
              </div>

            </React.Fragment>
            ) : (
              <React.Fragment>
                <div
                  className='journey-timeline-time-dot'
                  onClick={() => setActiveTime(2)}
                />
                <span
                  className='journey-timeline-time-date'
                  onClick={() => setActiveTime(2)}
                >
                  <FormattedMessage id='time3Month' />
                </span>
              </React.Fragment>
            )}
          </div>

          <div className='journey-timeline-time' >
          {activeTime === 3 ? (
            <React.Fragment>
              <div className='journey-timeline-time-dot-active' />
              <div className='journey-timeline-time-dot-active-inner' />
              <span className='journey-timeline-time-date active' >
                <FormattedMessage id='time4Month' />
              </span>

              <div className='journey-timeline-time-info top' >
                <div className='journey-timeline-time-info-left' >
                  <img src={TimelineLogo3} alt='Logo timeline oktober 2019' />
                </div>
                <div className='journey-timeline-time-info-right' >
                  <FormattedMessage id='time4Text' />
                </div>

                <div className='journey-timeline-time-info-triangle-bottom' />
              </div>

            </React.Fragment>
            ) : (
              <React.Fragment>
                <div
                  className='journey-timeline-time-dot'
                  onClick={() => setActiveTime(3)}
                />
                <span 
                  className='journey-timeline-time-date'
                  onClick={() => setActiveTime(3)}
                >
                  <FormattedMessage id='time4Month' />
                </span>
              </React.Fragment>
            )}
          </div>

          <div className='journey-timeline-time' >
          {activeTime === 4 ? (
            <React.Fragment>
              <div className='journey-timeline-time-dot-active' />
              <div className='journey-timeline-time-dot-active-inner' />
              <span className='journey-timeline-time-date active' >
                <FormattedMessage id='time5Month' />
              </span>

              <div className='journey-timeline-time-info' >
                <div className='journey-timeline-time-info-left' >
                  <img src={TimelineLogo5} alt='Logo timeline november 2019' />
                </div>
                <div className='journey-timeline-time-info-right' >
                  <FormattedMessage id='time5Text' />
                </div>

                <div className='journey-timeline-time-info-triangle' />
              </div>

            </React.Fragment>
            ) : (
              <React.Fragment>
                <div
                  className='journey-timeline-time-dot'
                  onClick={() => setActiveTime(4)}
                />
                <span
                  className='journey-timeline-time-date'
                  onClick={() => setActiveTime(4)}
                >
                  <FormattedMessage id='time5Month' />
                </span>
              </React.Fragment>
            )}
          </div>

          <div className='journey-timeline-time' >
          {activeTime === 5 ? (
            <React.Fragment>
              <div className='journey-timeline-time-dot-active' />
              <div className='journey-timeline-time-dot-active-inner' />
              <span className='journey-timeline-time-date active' >
                <FormattedMessage id='time6Month' />
              </span>

              <div className='journey-timeline-time-info top' >
                <div className='journey-timeline-time-info-left' >
                  <img src={TimelineLogo6} alt='Logo timeline maret 2020' />
                </div>
                <div className='journey-timeline-time-info-right' >
                  <FormattedMessage id='time6Text' />
                </div>

                <div className='journey-timeline-time-info-triangle-bottom' />
              </div>

            </React.Fragment>
            ) : (
              <React.Fragment>
                <div
                  className='journey-timeline-time-dot'
                  onClick={() => setActiveTime(5)}
                />
                <span
                  className='journey-timeline-time-date'
                  onClick={() => setActiveTime(5)}
                >
                  <FormattedMessage id='time6Month' />
                </span>
              </React.Fragment>
            )}

          </div>
          <div className='journey-timeline-time' >
          {activeTime === 6 ? (
            <React.Fragment>
              <div className='journey-timeline-time-dot-active' />
              <div className='journey-timeline-time-dot-active-inner' />
              <span className='journey-timeline-time-date active' >
                <FormattedMessage id='time7Month' />
              </span>

              <div className='journey-timeline-time-info' >
                <div className='journey-timeline-time-info-left' >
                  <img src={TimelineLogo7} alt='Logo timeline april 2020' />
                </div>
                <div className='journey-timeline-time-info-right' >
                  <FormattedMessage id='time7Text' />
                </div>

                <div className='journey-timeline-time-info-triangle' />
              </div>

            </React.Fragment>
            ) : (
              <React.Fragment>
                <div
                  className='journey-timeline-time-dot'
                  onClick={() => setActiveTime(6)}
                />
                <span
                  className='journey-timeline-time-date'
                  onClick={() => setActiveTime(6)}
                >
                  <FormattedMessage id='time7Month' />
                </span>
              </React.Fragment>
            )}

          </div>

          <div className='journey-timeline-time' >
          {activeTime === 7 ? (
            <React.Fragment>
              <div className='journey-timeline-time-dot-active' />
              <div className='journey-timeline-time-dot-active-inner' />
              <span className='journey-timeline-time-date active' >
                <FormattedMessage id='time8Month' />
              </span>

              <div className='journey-timeline-time-info top' >
                <div className='journey-timeline-time-info-left' >
                  <img src={TimelineLogo8} alt='Logo timeline september 2020' />
                </div>
                <div className='journey-timeline-time-info-right' >
                  <FormattedMessage id='time8Text' />
                </div>

                <div className='journey-timeline-time-info-triangle-bottom' />
              </div>

            </React.Fragment>
            ) : (
              <React.Fragment>
                <div
                  className='journey-timeline-time-dot'
                  onClick={() => setActiveTime(7)}
                />
                <span
                  className='journey-timeline-time-date'
                  onClick={() => setActiveTime(7)}
                >
                  <FormattedMessage id='time8Month' />
                </span>
              </React.Fragment>
            )}

          </div>

          <div className='journey-timeline-time' >
          {activeTime === 8 ? (
            <React.Fragment>
              <div className='journey-timeline-time-dot-active' />
              <div className='journey-timeline-time-dot-active-inner' />
              <span className='journey-timeline-time-date active' >
                <FormattedMessage id='time9Month' />
              </span>

              <div className='journey-timeline-time-info' >
                <div className='journey-timeline-time-info-left' >
                  <img src={TimelineLogo9} alt='Logo timeline oktober 2020' />
                </div>
                <div className='journey-timeline-time-info-right' >
                  <FormattedMessage id='time9Text' />
                </div>

                <div className='journey-timeline-time-info-triangle' />
              </div>

            </React.Fragment>
            ) : (
              <React.Fragment>
                <div
                  className='journey-timeline-time-dot'
                  onClick={() => setActiveTime(8)}
                />
                <span
                  className='journey-timeline-time-date'
                  onClick={() => setActiveTime(8)}
                >
                  <FormattedMessage id='time9Month' />
                </span>
              </React.Fragment>
            )}

          </div>

          <div className='journey-timeline-time' >
          {activeTime === 9 ? (
            <React.Fragment>
              <div className='journey-timeline-time-dot-active' />
              <div className='journey-timeline-time-dot-active-inner' />
              <span className='journey-timeline-time-date active' >
                <FormattedMessage id='time10Month' />
              </span>

              <div className='journey-timeline-time-info top' >
                <div className='journey-timeline-time-info-left' >
                  <img src={TimelineLogo10} alt='Logo timeline november 2020' />
                </div>
                <div className='journey-timeline-time-info-right' >
                  <FormattedMessage id='time10Text' />
                </div>

                <div className='journey-timeline-time-info-triangle-bottom' />
              </div>

            </React.Fragment>
            ) : (
              <React.Fragment>
                <div
                  className='journey-timeline-time-dot'
                  onClick={() => setActiveTime(9)}
                />
                <span
                  className='journey-timeline-time-date'
                  onClick={() => setActiveTime(9)}
                >
                  <FormattedMessage id='time10Month' />
                </span>
              </React.Fragment>
            )}

          </div>
          
          <div className='journey-timeline-time' />

        </div>

        <button
          className={`journey-timeline-btn right ${isNextActive ? 'active' : ''}`}
          onClick={() => isNextActive ? setNextClicks((num) => num + 1) : null}
        >
          <img src={RightArrow} alt='Arah panah ke kanan' />
        </button>
      </div>
    </IntlProvider>
  );
};

JourneyTimeline.propTypes = {
  isIndoSelected: PropTypes.bool,
};

export default JourneyTimeline;
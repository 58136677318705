import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, FormattedMessage } from 'react-intl';
import Achievement from '../../../Assets/Images/achievement.png';
import { englishMessages, bahasaMessages } from './AchievementsMessages';
import './Achievements.scss';

const Achievements = ({ isIndoSelected }) => {
  return (
    <IntlProvider
      messages={isIndoSelected ? bahasaMessages : bahasaMessages}
      locale='en'
      defaultLocale='id'
    >
      <div className='about-us-achievements' >

        <div>
          <span className='about-us-achievements-heading' >
            <FormattedMessage id='heading' />
          </span>
          <span className='about-us-achievements-main' >
            <FormattedMessage id='mainBlack1' />
            <br/>
            <span className='almond-text' >
              <FormattedMessage id='mainAlmond' />{' '}
            </span>
            <FormattedMessage id='mainBlack2' />
          </span>
          <img src={Achievement} className='about-us-achievements-img' alt='Pencapaian' />
        </div>

        <div className='about-us-achievements-right' >

          <div className='about-us-achievements-fact' >
            <div className='about-us-achievements-fact-left' >
              <FormattedMessage id='achievement1Heading' />
            </div>
            <div className='about-us-achievements-fact-right' >
              <FormattedMessage id='achievement1Text' />
            </div>
          </div>

          <div className='about-us-achievements-fact margin' >
            <div className='about-us-achievements-fact-left' >
              <FormattedMessage id='achievement2Heading' />
            </div>
            <div className='about-us-achievements-fact-right' >
              <FormattedMessage id='achievement2Text' />
            </div>
          </div>

          <div className='about-us-achievements-fact margin' >
            <div className='about-us-achievements-fact-left' >
              <FormattedMessage id='achievement3Heading' />
            </div>
            <div className='about-us-achievements-fact-right' >
              <FormattedMessage id='achievement3Text' />
            </div>
          </div>

        </div>
      </div>
    </IntlProvider>
  );
};

Achievements.propTypes = {
  isIndoSelected: PropTypes.bool,
};

export default Achievements;
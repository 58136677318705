import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, FormattedMessage } from 'react-intl';
import ReactPlayer from 'react-player'
import Like from "../../../Assets/Images/like.png";
import Love from "../../../Assets/Images/love.png";
import PlayerBg from '../../../Assets/Images/player-bg.jpg';
import { englishMessages, bahasaMessages } from './MissionMessages';
import './Mission.scss';

const Mission = ({ isIndoSelected }) => {

  const missionPlayerRef = useRef(null);

  const [videoUrl, setVideoUrl] = useState('https://storage.googleapis.com/titikpintar/20201221%20-%20Titik%20Pintar%20Website%20Video%20Bahasa.mp4');

  // useEffect(() => {
  //   if (isIndoSelected) return setVideoUrl('https://storage.googleapis.com/titikpintar/(Bahasa)%20Titik%20Pintar%20new%20website%20video.mp4');
  //   else return setVideoUrl('https://storage.googleapis.com/titikpintar/(English)%20Titik%20Pintar%20new%20website%20video.mp4');
  // }, [isIndoSelected]);

  // useEffect(() => {
  //   if (missionPlayerRef.current) {
  //     missionPlayerRef.current.load();
  //   }
  // }, [videoUrl]);

  return (
    <IntlProvider
      messages={isIndoSelected ? bahasaMessages : bahasaMessages}
      locale='en'
      defaultLocale='id'
    >
      <div className='about-us-mission' >
        <div className="homepage-narative-apps-row">

        <div>
          <span className="about-us-mission-heading">
            <FormattedMessage id='heading' />
          </span>

          <span className="about-us-mission-main">
            <span className='tablet-line-break' >
              <FormattedMessage id='mainBlack1' />
              <span className='almond-text' >
                <FormattedMessage id='mainAlmond1' />  
              </span>
              <FormattedMessage id='mainBlack2' /> 
              <span className='almond-text' >
                <FormattedMessage id='mainAlmond2' />
              </span>
                <FormattedMessage id='mainBlack3' />
              <span className='almond-text' >
                <FormattedMessage id='mainAlmond3' />
              </span>
            </span>
          </span>

            <p className="about-us-mission-para">
              <FormattedMessage id='para1' />
              {/* Titik Pintar is a gamification of the elementary school <br/>
              curriculum. We wish to ignite every elementary school <br/>
              student's curiosity, to learn by breathing life into their <br/>
              textbooks in an adaptable, bright, and colourful gaming <br/>
              application. */}
            </p>

            <p className="about-us-mission-para" >
              <FormattedMessage id='para2' />
              {/* We provide Bilingual National Curriculum learning materials <br/>
              that are aligned with the school's timeline, 21st Century Skills,<br/>
              Personalized Games, Online Competition & Collaboration,<br/>
              Progress Report and more.  */}
            </p>
          </div>

          <div className="about-us-mission-video-container">
            <img
              src={Like}
              alt="Suka"
              className="homepage-narative-apps-video-container-like"
            />
            <img
              src={Love}
              alt="Cinta"
              className="homepage-narative-apps-video-container-love one"
            />
            <img
              src={Love}
              alt="Cinta"
              className="homepage-narative-apps-video-container-love two"
            />
            <img
              src={Love}
              alt="Cinta"
              className="homepage-narative-apps-video-container-love three"
            />
            {/* <ReactPlayer url='https://prod-s3-direct-upload-bucket-sahabatpintar.s3.ap-southeast-1.amazonaws.com/Titik-Pintar-Website-Video.5af9c942.mp4' 
            controls={true} width='100%' height='65%'/> */}
            <ReactPlayer url='https://prod-s3-direct-upload-bucket-sahabatpintar.s3.ap-southeast-1.amazonaws.com/Titik-Pintar-Website-Video.5af9c942.mp4' 
            controls={true} width='100%' height='100%'
            light={PlayerBg}
            playing
            />
          </div>
        </div>
      </div>
    </IntlProvider>
  );
};

Mission.propTypes = {
  isIndoSelected: PropTypes.bool,
};

export default Mission;
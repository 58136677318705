import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, FormattedMessage } from 'react-intl';
import AppStoreDownload from "../../../Assets/Images/app-store-download.png";
import GooglePlayDownload from "../../../Assets/Images/google-play-download.png";
import Map from '../../../Assets/Images/map.png';
import Compass from '../../../Assets/Images/compass.png';
import Wave from '../../../Assets/Images/wave.png';
import SmallWave from '../../../Assets/Images/wave-small.png';
import Boat from '../../../Assets/Images/boat.png';
import { englishMessages, bahasaMessages } from './AboutHeaderMessages';
import './Header.scss';

const Header = ({ isIndoSelected }) => {
  return (
    <IntlProvider
      messages={isIndoSelected ? bahasaMessages : bahasaMessages}
      locale='en'
      defaultLocale='id'
    >

      <div className='about-us-header' >

        <img src={Map} className='about-us-header-map' alt='Peta' />

        <img src={Compass} className='about-us-header-compass' alt='Kompas' />

        <h1 className="about-us-header-heading">
          <FormattedMessage id='heading' />
        </h1>

        <p className="about-us-header-sub">
          {/* <span className='about-us-header-sub-line-desktop' > */}
            <FormattedMessage id='subHeading' />
          {/* </span> */}
        </p>

        <div className="about-us-header-download">
          {/*<a target="_blank" href="https://apps.apple.com/nl/app/titik-pintar/id1485849836">*/}
          {/*  <img*/}
          {/*    src={AppStoreDownload}*/}
          {/*    className="about-us-header-download-app-store "*/}
          {/*    alt=""*/}
          {/*  />*/}
          {/*</a>*/}
          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.tp.apk&referrer=utm_source%3Dwebsite%26utm_medium%3Dhomepage">
            <img
              src={GooglePlayDownload}
              alt="Tombol download di google play"
              className="about-us-header-download-google-play "
            />
          </a>
        </div>

        <img src={Boat} className='about-us-header-boat' alt='Perahu' />

        <img src={Wave} className='about-us-header-wave' alt='Gelombang'  />
        <img src={SmallWave} className='about-us-header-wave-small' alt='Gelombang kecil'  />

      </div>
    </IntlProvider>
  );
};

Header.propTypes = {
  isIndoSelected: PropTypes.bool,
};

export default Header;
import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, FormattedMessage } from 'react-intl';
import JourneyChildren from '../../../Assets/Images/journey-children.png';
import JourneyAdventurers from '../../../Assets/Images/journey-adventurers.png';
import JourneyTimeline from '../../../components/journeyTimeline/JourneyTimeline';
import { bahasaMessages, englishMessages } from './JourneyMessages';
import './Journey.scss';

const Journey = ({ isIndoSelected }) => {
  return (
    <IntlProvider
      messages={isIndoSelected ? bahasaMessages : bahasaMessages}
      locale='en'
      defaultLocale='id'
    >

      <div className='about-us-journey' >

        <img src={JourneyChildren} className='about-us-journey-children' alt='anak laki-laki dan anak perempuan' />

        <img src={JourneyAdventurers} className='about-us-journey-adventurers' alt='alby dan anak laki-laki' />

        <span className='about-us-journey-heading' >OUR JOURNEY</span>
        <span className='about-us-journey-main' >
          <FormattedMessage id='main' />
        </span>

        <p className='about-us-journey-para' >
          <FormattedMessage id='journeyPara' />
          {/* <span className='about-us-journey-para-line-desktop' >Inspired by how kids around the world maximize their gadgets to learn through a game,</span>
          Titik Pintar was born. Since 2019, we’ve been learning, growing, and experiencing an exciting journey! */}
        </p>

        <JourneyTimeline isIndoSelected={isIndoSelected} />
      </div>
    </IntlProvider>
  );
};

Journey.propTypes = {
  isIndoSelected: PropTypes.bool,
};

export default Journey;
import React, { useContext } from "react";

import Layout from "../components/Layout";

import Header from "../sections/aboutPage/header/Header"
import Mission from "../sections/aboutPage/mission/Mission"
import Journey from "../sections/aboutPage/journey/Journey"
import Team from "../sections/aboutPage/team/Team"
import Achievements from "../sections/aboutPage/achievements/Achievements"
import SmartNews from "../sections/aboutPage/smartNews/SmartNews"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../index.scss"

import { LangStateContext } from "../context/LangContext";

import SEO from '../components/seo/Seo'

const AboutPage = () => {
  const { isIndoSelected } = useContext(LangStateContext);
  console.log(isIndoSelected);

  return (
    <Layout>
      <SEO 
        title='Game edukasi berbasis kurikulum SD - Titik Pintar'
        description='Konten animasi dan game edukasi menyenangkan berbasis kurikulum SD dengan penjelasan yang mudah oleh guru berkualitas dan selaras dengan kurtilas.'
        keywords='Game, Edukasi, Kurikulum SD, Animasi, Game Edukasi menyenangkan, kurtilas, penjelasan mudah'
      />
      <div className='homepage' >
        <Header isIndoSelected={isIndoSelected} />
        <Mission isIndoSelected={isIndoSelected} />
        <Journey isIndoSelected={isIndoSelected} />  
        <Team isIndoSelected={isIndoSelected} />
        <Achievements isIndoSelected={isIndoSelected} />
        <SmartNews isIndoSelected={isIndoSelected} />
      </div>
    </Layout>
  );
};

export default AboutPage;

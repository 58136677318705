import React from 'react';
import { IntlProvider, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import PaperPlane from '../../../Assets/Images/paper-plane.png';
import { englishMessages, bahasaMessages } from './SmartNewsMessages';
import './SmartNews.scss';
import './SmartNewsMobile.scss';

const SmartNews = ({ isIndoSelected }) => {
  return (
    <IntlProvider
      messages={isIndoSelected ? bahasaMessages : bahasaMessages}
      locale='en'
      defaultLocale='id'
    >
      {/* Desktop Version */}
      <div className='about-us-smart-news' >
        <span className='about-us-smart-news-heading' >
          <FormattedMessage id='heading' />
        </span>
        <form name="contact-footer-desktop" method="post" action="https://formsubmit.co/hi@titikpintar.id">
          <input type="hidden" name="form-name" value="contact-footer-desktop" />
          <input type="hidden" name="_captcha" value="false" />
          <div className="about-us-smart-news-email">
            <FormattedMessage id='placeholder' >
              {placeholder =>
                <input
                type="text"
                className="helppage-subscribe-email-input"
                placeholder={placeholder}
                name="helppage-subscribe-email-input-desktop"
                />
              }
            </FormattedMessage>
            <button className="helppage-subscribe-email-btn" >
              <img src={PaperPlane} alt="Pesawat kertas" />
            </button>
          </div>
        </form>
      </div>

      {/* Mobile Version */}
      <div className='about-us-smart-news-mobile' >
        <span className='about-us-smart-news-mobile-heading' >
          <FormattedMessage id='heading' />
        </span>
        <form name="contact-footer-mobile" method="post" action="https://formsubmit.co/hi@titikpintar.id">
          <input type="hidden" name="_captcha" value="false" />
          <input type="hidden" name="form-name" value="contact-footer-mobile" />
          <div className="about-us-smart-news-mobile-email">
            <input
              type="text"
              className="helppage-subscribe-email-input"
              placeholder='Tulis email kamu di sini'
              name="helppage-subscribe-email-input-mobile"
            />
            <button className="about-us-smart-news-email-btn" >
              <img src={PaperPlane} alt="Pesawat kertas" />
            </button>
          </div>
        </form>
      </div>
    </IntlProvider>
  );
};

SmartNews.propTypes = {
  isIndoSelected: PropTypes.bool,
};

export default SmartNews;
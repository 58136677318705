import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { IntlProvider, FormattedMessage } from 'react-intl';
import TeamMember from '../../../components/teamMember/TeamMember';
import Bg from '../../../Assets/Images/blop.png';
import BgSmall from '../../../Assets/Images/blop-small.png';
import SuperBoy from '../../../Assets/Images/super-boy.png';
import SuperGirl from '../../../Assets/Images/super-girl.png';
import SuperBaldy from '../../../Assets/Images/super-baldy.png';
import SuperIndian from '../../../Assets/Images/super-indian.png';
import TeamShrub from '../../../Assets/Images/team-shrub.png';
import TeamCloudSmall from '../../../Assets/Images/team-cloud-small.png';
import TeamCloudSmall3 from '../../../Assets/Images/team-cloud-small-3.png';
import { englishMessages, bahasaMessages } from './TeamMessages';

// Members Images 
import Robbert from '../../../Assets/Images/ROBBERT-DEUSING.jpg';
import Ipan from '../../../Assets/Images/IPAN-ARDIAN.jpg';
import Kristina from '../../../Assets/Images/KRISTINA-HUTAPEA.jpg';
import Anant from '../../../Assets/Images/ANANT-SHARMA.jpg';
import Granite from '../../../Assets/Images/GRANITE-BAGAS.jpg';
import Aryanda from '../../../Assets/Images/ARYANDA-NUGRAHA.jpg';
import Indra from '../../../Assets/Images/INDRA-GREDUARD.jpg';
import Dimas from '../../../Assets/Images/DIMAS-RAMADIAN.jpg';
import Donny from '../../../Assets/Images/DONNY-CANDRA.jpg';

import './Team.scss';

const Team = ({ isIndoSelected }) => {

  const settings = {
    dots: false,
    autoplay: false,
    slidesToShow: 4,
    infinite: false,
    prevArrow: <button></button>,
    nextArrow: <button></button>,

    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <IntlProvider
      messages={isIndoSelected ? bahasaMessages : bahasaMessages}
      locale='en'
      defaultLocale='id'
    >

      <div className='about-us-team' >
        <img src={Bg} className='about-us-team-bg' alt='Langit' />

        <img src={BgSmall} alt='Langit' className='about-us-team-bg-small' />

        <img src={TeamCloudSmall} alt='Awan' className='about-us-team-cloud-small one' />
        <img src={TeamCloudSmall} alt='Awan' className='about-us-team-cloud-small two' />
        <img src={TeamCloudSmall3} alt='Awan' className='about-us-team-cloud-small three' />

        <img src={SuperBoy} alt="Anak laki-laki super" className='about-us-team-superboy' />
        <img src={SuperGirl} alt="Anak perempuan super" className='about-us-team-supergirl' />
        <img src={SuperBaldy} alt="Super Baldy" className='about-us-team-superbaldy' />
        <img src={SuperIndian} alt="Super indian" className='about-us-team-superindian' />
        <img src={TeamShrub} alt='Rumput' className='about-us-team-shrub-left' />
        <img src={TeamShrub} alt='Rumput' className='about-us-team-shrub-right' />

        <span className='about-us-team-heading' >
          <FormattedMessage id='heading' />
        </span>
        
        <span className='about-us-team-main' >
          <FormattedMessage id='main' />
        </span>

        <Slider {...settings} className='about-us-team-carousel-container' >
          <TeamMember
            img={Robbert}
            name='Robbert Deusing'
          />
          <TeamMember
            img={Ipan}
            name='Ipan Ardian'
          />
          <TeamMember
            img={Kristina}
            name='Kristina Hutapea'
          />
          <TeamMember
            img={Anant}
            name='Anant Sharma'
          />
          <TeamMember
            img={Granite}
            name='Granite Bagas'
          />
          <TeamMember
            img={Aryanda}
            name='Aryanda Nugraha'
          />
          <TeamMember
            img={Indra}
            name='Indra Greduard'
          />
          <TeamMember
            img={Dimas}
            name='Dimas Ramadian'
          />
          <TeamMember
            img={Donny}
            name='Donny Candra'
          />
        </Slider>

      </div>
    </IntlProvider>
  );
};

Team.propTypes = {
  isIndoSelected: PropTypes.bool,
};

export default Team;
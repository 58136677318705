import React from 'react';
import PropTypes from 'prop-types';
import './TeamMember.scss';

const TeamMember = ({ img, name }) => {
  return (
    <div className='team-member' >
      <div className='team-member-image-container' >
        <img src={img} alt={name} />
      </div>
      <span className='team-member-name' >{name}</span>
    </div>
  );
};

TeamMember.propTypes = {
  img: PropTypes.string,
  name: PropTypes.string,
};

export default TeamMember;
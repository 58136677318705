export const englishMessages = {
  time1Month: "May '19",
  time1Text: 'Received a grant from the Dutch Good Growth Fund (DGGF) from the Kingdom of Netherlands.',
  time2Month: "Jul '19",
  time2Text: 'First trial at a tutoring center, homeschooling community, and orphanage in Indonesia',
  time3Month: "Oct '19",
  time3Text: "Beta version launched on Google Play and App Store",
  time4Month: "Oct '19",
  time4Text: "Trials at 2 private schools in Jakarta",
  time5Month: "Nov '19",
  time5Text: "Third trial at 3 public schools and 1 private school in Indonesia",
  time6Month: "Mar '20",
  time6Text: 'Media exposure in Indonesia (Metro TV, Kompas, Daily Social, Katadata, Youngster.id, Mime Asia)',
  time7Month: "Apr '20",
  time7Text: 'Included in the Daily Social Launchpad accelerator program',
  time8Month: "Sep '20",
  time8Text: 'App officially released in Google Play Store and Apple App Store',
  time9Month: "Oct '20",
  time9Text: 'Selected to be an Impact Maker at The Impact Collective supported by UNDP (United Nations Development Programme)',
  time10Month: "Nov '20",
  time10Text: 'Represent in Indonesia in EDTECH Live Pitchaton at  #DraperStartUpHouse Singapore',
};

export const bahasaMessages = {
  time1Month: "Mei '19",
  time1Text: 'Mendapat hibah dari Dutch Good Growth Fund (DGGF) dari Kerajaan Belanda.',
  time2Month: "Jul '19",
  time2Text: 'Uji coba pertama di pusat bimbingan belajar, komunitas homeschooling, dan panti asuhan di Indonesia',
  time3Month: "Okt '19",
  time3Text: "Versi beta diluncurkan di Google Play dan App Store",
  time4Month: "Okt '19",
  time4Text: "Uji coba di 2 sekolah swasta di Jakarta",
  time5Month: "Nov '19",
  time5Text: "Uji coba ketiga di 3 sekolah negeri dan 1 sekolah swasta di Indonesia",
  time6Month: "Mar '20",
  time6Text: 'Eksposur media di Indonesia (Metro TV, Kompas, Daily Social, Katadata, Youngster.id, Mime Asia)',
  time7Month: "Apr '20",
  time7Text: 'Masuk di Daily Social Launchpad accelerator program',
  time8Month: "Sep '20",
  time8Text: 'Resmi rilis di Google Play Store dan Apple App Store',
  time9Month: "Okt '20",
  time9Text: 'Terpilih menjadi Impact Maker di The Impact Collective yang didukung oleh UNDP (United Nations Development Programme)',
  time10Month: "Nov '20",
  time10Text: 'Mewakili Indonesia di EDTECH Live Pitchaton di #DraperStartUpHouse Singapura'
};
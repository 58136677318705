export const englishMessages = {
  para1: "Titik Pintar is a gamification of the elementary school curriculum. We wish to ignite every elementary school student's curiosity, to learn by breathing life into their textbooks in an adaptable, bright, and colourful gaming application.",
  para2: 'We provide Bilingual National Curriculum learning materials that are aligned with the school’s timeline, 21st Century Skills, Personalized Games, Online Competition & Collaboration, Progress Report, and more.',
  heading: 'OUR MISSION',
  mainBlack1: "To be a ",
  mainAlmond1: "sanctuary ",
  mainBlack2: 'for ',
  mainAlmond2: "child's imagination ",
  mainBlack3: 'and ',
  mainAlmond3: 'learning',
};

export const bahasaMessages = {
  para1: "Titik Pintar merupakan gamifikasi dari Kurikulum Sekolah Dasar. Kami ingin menyulut keingintahuan setiap siswa sekolah dasar, untuk belajar dengan mentransformasi pelajaran sekolah menjadi aplikasi game yang menyenangkan dan penuh warna.",
  para2: 'Kami menyediakan materi pembelajaran Kurikulum Nasional Bilingual yang selaras dengan pelajaran di sekolah, keterampilan abad 21, dan banyak lagi. Dikemas dalam permainan yang kompetitif dan sehat, Titik Pintar berharap anak-anak bisa menjadi pribadi yang cemerlang.',
  heading: 'MISI KAMI',
  mainBlack1: "Menjadi ",
  mainAlmond1: "surganya ",
  mainBlack2: ' ',
  mainAlmond2: "imajinasi dan pembelajaran ",
  mainBlack3: 'untuk ',
  mainAlmond3: 'anak-anak',
};